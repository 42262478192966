
.text{
    font-size: 35px;
    font-family: Arial;
    color: aliceblue;
}

.btn{
    height: 50px;
    width: 100px;
    font-size: 18px;
    background-color: aliceblue;
    border-width: 3px;
    font-family: ROBOTO;
}

.btn:hover{
    background-color: rgb(0, 0, 0);
    color: aliceblue;
}