.Area{
    margin-top: 5%;
    width: 90%;
    height: 500px;
    border-radius: 10px;
    background-color: cadetblue;
}

.Body{
    font-size: 25px;
    font-family: calibri;
    color: azure;
    font-weight: 500;
}

.Title{
    font-size: 50px;
    font-family: Roboto;
    color: aliceblue;
}

