.btn.btn-outline-secondary{
    width: 120px;
    height: 40px;
    border: none;
    font-size: 18px;
    font-family: ROBOTO;  
    color: rgb(29, 32, 32);
    background-color: rgb(255, 255, 255);
}

.btn.btn-outline-secondary:hover{
    color: aliceblue;
    background-color: rgb(29, 32, 32);
}