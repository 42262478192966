.navbar{
    font-family: ROBOTO;
    height: 20%;
    width: 100%;
    font-size: 16px;
    background-color:rgb(123, 200, 202)
}

.logo{
    font-size: 18px;
    margin-left: 1%;
}

.pages{
    width: 100%;
    margin-left: 1%;
    margin-right: 35%;
}

.d-flex{
    width: 100%;
    height: 21%;
}

.search{
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    margin-left: 1%;
    margin-right: 1%;
    width: 100%;
    height: 40px;
    background-color: rgba(240, 248, 255, 0.726);
}

/* Custom styles for the navbar button */
.btn.custom-button {
    margin-top: 1.5%;
    margin-bottom: 1.5%;
    margin-left: 0.5%;
    width: 100px;
    height: 41px;
    font-family: ROBOTO;
    font-size: 16px;
    background-color:rgb(0, 0, 0); /* Change the background color on hover */
    color:rgb(255, 255, 255); /* Change the text color */
    border: none; /* Remove border */

  }
  
  .btn.custom-button:hover {
    background-color:rgb(255, 255, 255); /* Change the background color on hover */
    color:rgb(1, 1, 1); /* Change the text color on hover */
  }